<template>
  <ModelCreater :title="title" :loading="isLoading" :errors="errors" :canCreate="canCreate" @create="create">
    <CreateLoggerStyled>
      <InputField :labelWidth="6" class="brand">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.brand.$model" placeholder="Brand" />
        </template>
        <template v-slot:label> Brand </template>
      </InputField>
      <InputField :labelWidth="9" class="supplierProjectID">
        <template v-slot:input>
          <input type="text" v-model.trim="supplierProjectID" placeholder="Supplier project" />
        </template>
        <template v-slot:label> Supplier Project </template>
      </InputField>
      <InputField :labelWidth="9" class="constructionDate">
        <template v-slot:input>
          <input type="date" v-model.trim="$v.constructionDate.$model" placeholder="eg. 2021-06-21T03:37:00" class="date-picker" />
        </template>
        <template v-slot:label> Construction Date </template>
      </InputField>
      <InputField :labelWidth="8" class="serialNumber">
        <template v-slot:input>
          <input type="text" v-model.trim="$v.serialNumber.$model" placeholder="eg. indurad-id a1b2c3" />
        </template>
        <template v-slot:label> Serial Number *</template>
        <template v-slot:errors v-if="$v.serialNumber.$dirty">
          <div class="error" v-if="!$v.serialNumber.required">Field is required</div>
          <div class="error" v-if="!$v.serialNumber.minLength">
            Field must have at least {{ $v.serialNumber.$params.minLength.min }} letters.
          </div>
        </template>
      </InputField>
      <div class="type">
        <div class="type-label">Manufacturer *</div>
        <Multiselect
          :options="organizations"
          :value="selectedManufacturer"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setOrganization"
        />
      </div>
      <div class="type">
        <div class="type-label">
          {{ $tc('loggerModel', 1) }}
        </div>
        <Multiselect
          :options="loggerModels"
          :value="selectedLoggerModel"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setLoggerModel"
        />
        <div class="add-icon">
          <PlusCircleIcon @click="$root.$emit('activateOverlay', 'CreateLoggerModelOverlay')" />
        </div>
      </div>
      <div class="type">
        <div class="type-label">Supplier</div>
        <Multiselect
          :options="organizations"
          :value="selectedSupplierModel"
          :allow-empty="true"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setSupplierModel"
        />
      </div>
      <div class="type">
        <div class="type-label">Legal Owner *</div>
        <Multiselect
          :options="organizations"
          :value="selectedLegalOwner"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="name"
          @select="setLegalOwner"
        />
      </div>
      <div class="type">
        <div class="type-label">Status *</div>
        <Multiselect
          :options="hardwareStatuses"
          :value="selectedStatus"
          :allow-empty="false"
          open-direction="top"
          deselect-label=""
          trackBy="id"
          label="title"
          @select="setSelectedStatus"
        />
      </div>
      <InputField :labelWidth="9" class="PasswordHash">
        <template v-slot:input>
          <input
            type="text"
            v-model.trim="$v.passwordHash.$model"
            placeholder="$2y$10$pHY9LfuaSr4PVV4gcbqKBeTW5IfgutCWzVHRHHmBSlkS/bjZN1OFu"
          />
        </template>
        <template v-slot:errors v-if="$v.passwordHash.$dirty">
          <div class="error" v-if="!$v.passwordHash.required">Field is required</div>
        </template>
        <template v-slot:label> Password Hash *</template>
      </InputField>
      <InputField :labelWidth="6" class="notes">
        <template v-slot:input>
          <input type="text" v-model.trim="notes" placeholder="notes" />
        </template>
        <template v-slot:label> Notes </template>
      </InputField>
      <InputField :labelWidth="6" class="firmware">
        <template v-slot:input>
          <input type="text" v-model.trim="firmware" placeholder="firmware" />
        </template>
        <template v-slot:label> Firmware </template>
      </InputField>
    </CreateLoggerStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ModelCreater from '@/components/misc/ModelCreater'
import { InputField } from '@common/components'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import chroma from 'chroma-js'
import { FlashMessages } from '@common/singletons'
import { PlusCircleIcon } from 'vue-feather-icons'
import Multiselect from 'vue-multiselect'
import { flexCenter } from '@styles/mixins'
import { required, minLength } from 'vuelidate/lib/validators'
// import { OnOffToggle } from '@common/components'

import CREATE_LOGGER_MUTATION from '#/graphql/loggers/createLogger.gql'
import LOGGER_MODELS_QUERY from '#/graphql/loggerModels/models.gql'
import HARDWARE_STATUS_QUERY from '#/graphql/loggers/hardwareStatuses.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/organizations.gql'

const CreateLoggerStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, min-content);
  grid-gap: 1rem;
  align-items: center;
  .type {
    position: relative;
    max-width: 25rem;
    display: flex;
    align-items: left;
    background: ${p => chroma(p.theme.colors.black).alpha(0.6).css()};
    backdrop-filter: blur(7px);
    padding: 0.25rem;
    .type-label {
      ${flexCenter}
      min-width: 8rem;
      font-size: 16px;
      padding: 0.25rem;
      color: ${p => p.theme.colors.archonBlue};
      height: 2.75rem;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    }
    .basic-select {
      max-width: 20rem;
      margin-left: 1rem;
    }
  }
  .add-icon {
    ${flexCenter}
    margin-left: 2rem;
    width: 80px;
    height: 40px;
    background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};
    &:hover {
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
    }
  }
  .date-picker::-webkit-calendar-picker-indicator {
    cursor: pointer;
    margin: 0;
    padding: 0;
    filter: invert(1);
  }
`

export default {
  components: {
    CreateLoggerStyled,
    ModelCreater,
    Multiselect,
    InputField,
    PlusCircleIcon,
  },
  data() {
    return {
      brand: '',
      password: '',
      supplierProjectID: '',
      errors: [],
      organizations: [],
      selectedManufacturer: null,
      loggerModels: [],
      selectedLoggerModel: null,
      selectedSupplierModel: null,
      selectedLegalOwner: null,
      selectedStatus: null,
      isLoading: false,
      isCreating: false,
      serialNumber: '',
      constructionDate: '',
      passwordHash: '',
      notes: '',
      firmware: '',
      hasGps: true,
      selectedTimezone: {
        id: 'browser',
        title: 'Browser',
        value: DateTime.local().zoneName,
      },
      hardwareStatuses: [],
    }
  },
  computed: {
    title() {
      return `Create Logger`
    },
    canCreate() {
      return (
        !this.$v.$invalid &&
        !!get(this.selectedManufacturer, 'id') &&
        !!get(this.selectedLoggerModel, 'id') &&
        !!get(this.selectedSupplierModel, 'id') &&
        !!get(this.selectedStatus, 'id') &&
        !this.isCreating
      )
    },
  },
  validations: {
    serialNumber: {
      required,
      minLength: minLength(5),
    },
    brand: {
      required,
    },
    constructionDate: {
      required,
    },
    passwordHash: {
      required,
    },
  },
  methods: {
    setOrganization(organization) {
      this.selectedManufacturer = organization
    },
    setLoggerModel(model) {
      this.selectedLoggerModel = model
    },
    setSupplierModel(model) {
      this.selectedSupplierModel = model
    },
    setLegalOwner(model) {
      this.selectedLegalOwner = model
    },
    setSelectedStatus(model) {
      this.selectedStatus = model
    },
    async create() {
      this.isCreating = true
      const data = {
        manufacturerCuid: this.selectedManufacturer.id,
        modelId: this.selectedLoggerModel.id,
        ownerCuid: this.selectedLegalOwner.id,
        password: this.passwordHash,
        serialNumber: this.serialNumber,
        statusId: this.selectedStatus.id,
        supplierCuid: this.selectedSupplierModel.id,
      }
      if (this.brand) {
        data.brand = this.brand
      }
      if (this.supplierProjectID) {
        data.supplierProject = this.supplierProjectID
      }
      if (this.notes) {
        data.notes = this.notes
      }
      if (this.firmware) {
        data.firmware = this.firmware
      }
      if (this.firmware) {
        data.firmware = this.firmware
      }
      if (this.constructionDate) {
        data.constructionDate = this.constructionDate
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_LOGGER_MUTATION,
          variables: {
            data,
          },
        })
        const id = get(res, 'data.createLogger.id')
        if (id) {
          this.$emit('reload')
          this.$router.push({
            name: 'loggers',
            params: {
              id,
            },
          })
        }
      } catch (err) {
        this.errors.push(err)
        FlashMessages.$emit('error', err)
      }
      this.isCreating = false
    },
  },
  apollo: {
    loggerModels: {
      query: LOGGER_MODELS_QUERY,
    },
    hardwareStatuses: {
      query: HARDWARE_STATUS_QUERY,
    },
    organizations: {
      query: ORGANIZATIONS_QUERY,
    },
  },
}
</script>
