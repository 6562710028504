var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.isLoading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate
    },
    on: {
      "create": _vm.create
    }
  }, [_c('CreateLoggerStyled', [_c('InputField', {
    staticClass: "brand",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.brand.$model,
            expression: "$v.brand.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Brand"
          },
          domProps: {
            "value": _vm.$v.brand.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.brand, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Brand ")];
      },
      proxy: true
    }])
  }), _c('InputField', {
    staticClass: "supplierProjectID",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.supplierProjectID,
            expression: "supplierProjectID",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "Supplier project"
          },
          domProps: {
            "value": _vm.supplierProjectID
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.supplierProjectID = $event.target.value.trim();
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Supplier Project ")];
      },
      proxy: true
    }])
  }), _c('InputField', {
    staticClass: "constructionDate",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.constructionDate.$model,
            expression: "$v.constructionDate.$model",
            modifiers: {
              "trim": true
            }
          }],
          staticClass: "date-picker",
          attrs: {
            "type": "date",
            "placeholder": "eg. 2021-06-21T03:37:00"
          },
          domProps: {
            "value": _vm.$v.constructionDate.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.constructionDate, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Construction Date ")];
      },
      proxy: true
    }])
  }), _c('InputField', {
    staticClass: "serialNumber",
    attrs: {
      "labelWidth": 8
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.serialNumber.$model,
            expression: "$v.serialNumber.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "eg. indurad-id a1b2c3"
          },
          domProps: {
            "value": _vm.$v.serialNumber.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.serialNumber, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Serial Number *")];
      },
      proxy: true
    }, _vm.$v.serialNumber.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.serialNumber.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e(), !_vm.$v.serialNumber.minLength ? _c('div', {
          staticClass: "error"
        }, [_vm._v(" Field must have at least " + _vm._s(_vm.$v.serialNumber.$params.minLength.min) + " letters. ")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Manufacturer *")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedManufacturer,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setOrganization
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v(" " + _vm._s(_vm.$tc('loggerModel', 1)) + " ")]), _c('Multiselect', {
    attrs: {
      "options": _vm.loggerModels,
      "value": _vm.selectedLoggerModel,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setLoggerModel
    }
  }), _c('div', {
    staticClass: "add-icon"
  }, [_c('PlusCircleIcon', {
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('activateOverlay', 'CreateLoggerModelOverlay');
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Supplier")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedSupplierModel,
      "allow-empty": true,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setSupplierModel
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Legal Owner *")]), _c('Multiselect', {
    attrs: {
      "options": _vm.organizations,
      "value": _vm.selectedLegalOwner,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "name"
    },
    on: {
      "select": _vm.setLegalOwner
    }
  })], 1), _c('div', {
    staticClass: "type"
  }, [_c('div', {
    staticClass: "type-label"
  }, [_vm._v("Status *")]), _c('Multiselect', {
    attrs: {
      "options": _vm.hardwareStatuses,
      "value": _vm.selectedStatus,
      "allow-empty": false,
      "open-direction": "top",
      "deselect-label": "",
      "trackBy": "id",
      "label": "title"
    },
    on: {
      "select": _vm.setSelectedStatus
    }
  })], 1), _c('InputField', {
    staticClass: "PasswordHash",
    attrs: {
      "labelWidth": 9
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.$v.passwordHash.$model,
            expression: "$v.passwordHash.$model",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "$2y$10$pHY9LfuaSr4PVV4gcbqKBeTW5IfgutCWzVHRHHmBSlkS/bjZN1OFu"
          },
          domProps: {
            "value": _vm.$v.passwordHash.$model
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.$set(_vm.$v.passwordHash, "$model", $event.target.value.trim());
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, _vm.$v.passwordHash.$dirty ? {
      key: "errors",
      fn: function fn() {
        return [!_vm.$v.passwordHash.required ? _c('div', {
          staticClass: "error"
        }, [_vm._v("Field is required")]) : _vm._e()];
      },
      proxy: true
    } : null, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Password Hash *")];
      },
      proxy: true
    }], null, true)
  }), _c('InputField', {
    staticClass: "notes",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.notes,
            expression: "notes",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "notes"
          },
          domProps: {
            "value": _vm.notes
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.notes = $event.target.value.trim();
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Notes ")];
      },
      proxy: true
    }])
  }), _c('InputField', {
    staticClass: "firmware",
    attrs: {
      "labelWidth": 6
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function fn() {
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model.trim",
            value: _vm.firmware,
            expression: "firmware",
            modifiers: {
              "trim": true
            }
          }],
          attrs: {
            "type": "text",
            "placeholder": "firmware"
          },
          domProps: {
            "value": _vm.firmware
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }
              _vm.firmware = $event.target.value.trim();
            },
            "blur": function blur($event) {
              return _vm.$forceUpdate();
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Firmware ")];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }